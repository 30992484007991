export const numberWithSpace = (x) => {
    if (x !== undefined) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    } else {
        return x
    }
}

export const colors = () => {
    var x = ['#f56a00', '#87d068', '#1890ff', '#fde3cf'];
    var y = Math.floor(Math.random() * 3)
    return x[y]
}

export const addDaysToDate = (date, days) => {
    var result = new Date();
    result.setDate(date.getDate() + days);
    return result;
}

export const displayPriceCurrency = (price, currency, type) => {
    if (price !== undefined) {
        if (type === 1) {
            return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " " + currency;
        } else {
            return currency + " " + price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        }
    } else {
        return 0
    }
}