let initialState = {
    useGiftCard: false,
}

//load cart items from local storage
if (typeof window !== 'undefined') {
    if (localStorage.getItem('paymentInfo')) {
        initialState = JSON.parse(localStorage.getItem('paymentInfo'))
    }
}


const paymentReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_PAYMENT":
            return action.payload;
        default:
            return state;
    }
}
export default paymentReducer;