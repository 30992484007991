let initialState = []

//load cart items from local storage
if (typeof window !== 'undefined') {
    if (localStorage.getItem('localGC')) {
        initialState = JSON.parse(localStorage.getItem('localGC'))
    } else {
        initialState = [];
    }
}

const localcatsReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ADD_TO_LOCALGIFTCARD":
            return action.payload;
        default:
            return state;
    }
}
export default localcatsReducer;