import React from 'react'
import { Drawer, Button, Divider, Row, Col } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { CloseOutlined } from '@ant-design/icons';
import { numberWithSpace } from '../../functions';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { translate } from '../../i18n';

const SideDrawer = ({ children }) => {
    const dispatch = useDispatch()
    const { drawer, cart } = useSelector((state) => ({ ...state }))
    const { language } = useSelector((state) => state.lang);
    const isMobile = useWindowDimensions().width <= 768;
    const onClose = () => {
        dispatch({
            type: "SET_VISIBLE",
            payload: false,
        })
    }

    return (
        <>
            {/* <Drawer
            onClose={() => {
                dispatch({
                    type: "SET_VISIBLE",
                    payload: false,
                })
            }}
            visible={drawer}
            //visible={true}
            className="text-center"
        >
            <div className="headerSideDrawer mb-4">
                <h2>
                    {`Panier / ${cart.length} articles`}
                </h2>
            </div>
            
        </Drawer> */}
            <Drawer
                width={isMobile ? "80%" : "350px"}
                onClose={onClose}
                visible={drawer}
                closable={false}
                bodyStyle={{ paddingBottom: 80 }}
            >
                <Row justify="space-between" gutter={[40, 0]} align="middle" className="headerSideDrawer mb-3 pr-5">
                    <Col xl={4} lg={4} md={4} sm={3} xs={3} className="pb-2">
                        <Button type="link" onClick={onClose} >
                            <CloseOutlined style={{ color: '#fff', fontSize: '16px' }} />
                        </Button>
                    </Col>
                    <Col span={20}>
                        <h3 className="p-0 pl-1" style={{ fontWeight: 'bold' }}>{`${translate("yourCart", language)} : ${cart.length} articles`}</h3>
                    </Col>
                </Row>
                {
                    cart.map((p) => (
                        <div key={p.virtualId} className="row">
                            <div className="col">
                                {
                                    p && (
                                        <div className="row pr-2" style={{ textAlign: 'left', marginLeft: '-10px' }}>
                                            <div className="col-4 mb-3">
                                                <Link to={p.link}>
                                                    <div className="small-img" >
                                                        <img
                                                            alt={p.name_fr}
                                                            //style={imageStyle}
                                                            style={{ maxHeight: '100%', maxWidth: '100%' }}
                                                            src={p.color && p.color ? p.color : p.images[0].url}
                                                        />
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="col-8" style={{ padding: '0px 10px 0px 0px', margin: '0px' }}>
                                                <p style={{ marginLeft: '-10px', lineHeight: '14px', color: 'var(--main-green)', fontSize: '12px' }} className="text-left mb-0">
                                                    <Link className="non-deco" to={p.link}>
                                                        {p.name_fr.length > 80 ? `${p.name_fr.substring(0, 80)}...` : p.name_fr}
                                                    </Link>
                                                </p>
                                                <p style={{ color: 'var(--main-yellow)', fontSize: '12px' }} className="mt-1">{p.count} x {p.price && numberWithSpace(p.price)} FCFA</p>
                                            </div>
                                            <Divider className="mb-3 mt-0" />
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    ))
                }
                <div className="mb-5 mt-2 text-center">
                    <Link to="/cart">
                        <Button type="primary" ghost
                            onClick={() => {
                                dispatch({
                                    type: "SET_VISIBLE",
                                    payload: false
                                })
                            }}
                            className="text-center btn btn-primary btn-raised btn-block">
                            Aller au panier
                        </Button>
                    </Link>
                </div>
            </Drawer>
        </>
    )
}

export default SideDrawer;