import React from 'react';
import { Link } from 'react-router-dom';
import { BiLayer, BiUser } from 'react-icons/bi';
import { HomeOutlined, MessageOutlined, TagsOutlined } from '@ant-design/icons';
import { MdCardGiftcard } from 'react-icons/md'
//import LiveTvIcon from '@material-ui/icons/LiveTv';
import { useSelector } from 'react-redux';
import { translate } from '../../i18n'
import './BottomNavBar.scss';

const BottomNavBar = () => {
    const { language } = useSelector((state) => state.lang);

    return (
        <div className="nav mt-5">
            <Link to="/" className="nav__link">
                <HomeOutlined style={{ color: 'var(--main-yellow)' }} className="material-icons nav__icon" />
                <span className="nav__text">{translate("home", language)}</span>
            </Link>
            <Link to="/categories" className="nav__link">
                <BiLayer style={{ color: 'var(--main-yellow)' }} className="material-icons nav__icon" />
                <span className="nav__text">{translate("categories", language)}</span>
            </Link>
            <Link to="/promotions" className="nav__link">
                <TagsOutlined style={{ color: 'var(--main-yellow)' }} className="material-icons nav__icon" />
                <span className="nav__text">Promotions</span>
            </Link>
            {/* <Link to="/aide" className="nav__link">
                <QuestionCircleOutlined style={{ color: 'var(--main-green)' }} className="material-icons nav__icon" />
                <span className="nav__text">{translate("help", language)}</span>
            </Link> */}
            <Link to="/contact" className="nav__link">
                <MessageOutlined style={{ color: 'var(--main-yellow)' }} className="material-icons nav__icon" />
                <span className="nav__text">Contact</span>
            </Link>
            <Link to="/moncompte" className="nav__link">
                <BiUser style={{ color: 'var(--main-yellow)' }} className="material-icons nav__icon" />
                <span className="nav__text">{translate("header_listAccount", language)}</span>
            </Link>
        </div>
    )
}

export default BottomNavBar;