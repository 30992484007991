import React, { useState } from 'react';
import { Drawer, Button, Col, Row, Input, notification, Alert } from 'antd';
import { MailOutlined, LockOutlined, CloseOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BsArrowLeft } from 'react-icons/bs'
import { login } from '../../../functions/auth';
import { showSuccessMessage, showErrorMessage } from '../../../helpers/alerts';
import { authenticate, isAuth } from '../../../helpers/auth';
import validate from '../../../components/FormValidation/LoginValidation';
import { translate } from '../../../i18n';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import './Login.scss'

const DrawerLogin = () => {
    const isMobile = useWindowDimensions().width <= 768;
    const dispatch = useDispatch()
    const { language } = useSelector((state) => state.lang);
    const { loginDrawer } = useSelector((state) => state);
    const [values, setValues] = useState({
        email: '',
        password: '',
        error: '',
        success: ''
    });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false)
    const { email, password, error, success } = values;

    const onClose = () => {
        dispatch({
            type: "SET_VISIBLE_LOGIN_DRAWER",
            payload: false,
        })
    };

    const handleChange = (e) => {
        setValues({ ...values, error: '', success: '', [e.target.name]: e.target.value });
        //console.log('event', e.target.value)
    };

    const handleSubmit = async e => {
        e.preventDefault();
        setValues({ ...values, buttonText: 'Logging in' });
        setErrors(validate(values));
        //console.log('errors', validate(values))
        if (validate(values).email || validate(values).password) {
            return;
        } else {
            try {
                setLoading(true)
                login(email, password)
                    .then((response) => {
                        authenticate(response, () => {
                            let currentCustomer = isAuth()
                            //isAuth() && console.log('RRRRR', currentCustomer)
                            dispatch({
                                type: 'LOGGED_IN_CUSTOMER',
                                payload: {
                                    firstName: currentCustomer.firstName,
                                    lastName: currentCustomer.lastName,
                                    displayName: currentCustomer.displayName,
                                    lettersAvatar: currentCustomer.lettersAvatar,
                                    email: currentCustomer.email,
                                    phone: currentCustomer.phone,
                                    city: currentCustomer.city,
                                    dateOfBirth: currentCustomer.dateOfBirth,
                                    gender: currentCustomer.gender,
                                    phoneCode: currentCustomer.phoneCode,
                                    country: currentCustomer.country,
                                    idCode: currentCustomer.idCode,
                                    createdAt: currentCustomer.createdAt
                                }
                            });
                            notification["success"]({
                                message: 'Succès !',
                                description: 'Connexion au compte réussie !',
                            });
                            setLoading(false)
                            onClose()
                            window.location.reload();
                        }
                        )
                    })
                    .catch((error) => {
                        //console.log('err1', error.response.data.error);
                        setValues({ ...values, error: error.response.data.error });
                        setLoading(false)
                    })
            } catch (error) {
                //console.log('err1', error.response.data.error);
                setValues({ ...values, error: error.response.data.error });
                setLoading(false)
            }
        }
    };


    return (
        <>
            <Drawer
                width={isMobile ? "100%" : "400px"}
                onClose={false}
                visible={loginDrawer}
                closable={false}
                bodyStyle={{ paddingBottom: 80 }}
            >
                <Row justify="space-between" gutter={[40, 0]} align="middle" className="headerSideDrawer1 mb-4 pr-5">
                    <Col xl={0} lg={0} md={0} sm={4} xs={4} className="pb-2">
                        <Button type="link" onClick={onClose} >
                            <BsArrowLeft style={{ color: 'var(--main-yellow)', fontSize: '30px' }} />
                        </Button>
                    </Col>
                    <Col span={20}>
                        <h3 className="p-0 pl-3" style={{ fontWeight: 'bold' }}>Connexion</h3>
                    </Col>
                    <Col xl={4} lg={4} md={4} sm={0} xs={0} className="pb-2">
                        <Button type="link" onClick={onClose} >
                            <CloseOutlined style={{ color: 'var(--main-yellow)', fontSize: '20px' }} />
                        </Button>
                    </Col>
                </Row>
                <Row gutter={[4, 4]} className="mt-5 pt-5 pl-4 pr-4">
                    <Col span={24}>
                        <Alert message={translate("favorisTitleConnexion", language)} type="warning" showIcon />
                    </Col>
                    <Col span={24}>
                        {success && showSuccessMessage(success)}
                        {error && showErrorMessage('Échec !', error)}
                        <div>
                            <form onSubmit={handleSubmit} noValidate className="login-form mb-1 pr-0" style={{ width: '100%' }}>
                                <div className="mt-4" style={{ width: '100%' }}>
                                    <Input type="email" size="large" disabled={loading}
                                        className={errors.email ? "form-input-error" : "form-input"}
                                        prefix={<MailOutlined className="site-form-item-icon" style={{ color: '#f08804', marginRight: 5 }} />}
                                        placeholder="Email"
                                        name="email"
                                        value={email}
                                        onChange={handleChange}
                                    />
                                    {errors.email && <span className="displayError">{errors.email}</span>}
                                </div>
                                <div className="mt-4">
                                    <Input size="large" disabled={loading}
                                        className={errors.password ? "form-input-error" : "form-input"}
                                        prefix={<LockOutlined className="site-form-item-icon" style={{ color: '#f08804', marginRight: 5 }} />}
                                        type="password"
                                        placeholder={translate("Ctm_profil_pass", language)}
                                        name="password"
                                        value={password}
                                        onChange={handleChange}
                                    />
                                    <span className="displayError">{errors.password && errors.password}</span>
                                </div>
                                <div className="text-center mt-5" >
                                    <Button loading={loading} type="primary" size="large" style={{ width: '100%', boxShadow: '0 4px 2px -2px gray' }} htmlType="submit" className="login-form-button" >
                                        Connexion
                                    </Button>
                                </div>
                            </form>
                        </div>
                        <Row className="mt-5">
                            <Col span={12}>
                                <div>
                                    <Link onClick={onClose} className="login-form return-login-register" style={{ color: 'var(--main-yellow)' }} to="/forgot-password">
                                        {translate("login_forget", language)}
                                    </Link>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div>
                                    {translate("or", language)} <Link onClick={onClose} to="/register" style={{ color: 'var(--main-yellow)' }} className="return-login-register">{translate("login_register_label", language)}</Link>
                                </div>
                            </Col>
                        </Row>
                        {/* <Divider className="mt-5 mb-5" orientation="center" style={{ color: 'var(--third-black)' }}>OU</Divider>
                        <Button type="primary" ghost size="large"
                            style={{
                                width: '100%', boxShadow: '0 4px 3px -2px gray',
                                border: '1px solid var(--main-grey)', color: 'var(--second-black)',
                                height: '55px'
                            }}
                        >
                            <Row align="middle">
                                <Col span={2}>
                                    <img src={GoogleIcon} style={{ width: '100%' }} />
                                </Col>
                                <Col className="pl-4" style={{ fontSize: '18px' }}>
                                    Connexion avec Google
                                </Col>
                            </Row>
                        </Button> */}
                    </Col>
                </Row>
            </Drawer>
        </>
    )
}

export default DrawerLogin;