import React, { useState } from "react";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import { Drawer, Button } from "antd";
import { FiMenu } from 'react-icons/fi';
import { BiChevronRight } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { FaUserCircle } from "react-icons/fa";
//import LiveTvIcon from '@material-ui/icons/LiveTv';
import { LeftOutlined, UserOutlined, ShopOutlined, QuestionCircleOutlined, GiftOutlined, MessageOutlined, TagsOutlined } from "@ant-design/icons";
import { translate } from '../../i18n';
//import { menuGroups } from '../../pages/data2';
import "./Header.scss";

const displayIcon = (ico) => {
    switch (ico) {
        case 1:
            return (<TagsOutlined />)
        case 2:
            return (<GiftOutlined />)
        case 3:
            return (<UserOutlined />)
        case 4:
            return (<QuestionCircleOutlined />)
        case 5:
            return (<MessageOutlined />)
        case 6:
            return (<ShopOutlined />)
        default:
            return (null)
    }
}

const MenuDrawer = ({ menuGroups }) => {

    const initialMenuTitle = {
        title_fr: "",
        title_en: ""
    }

    const { language } = useSelector((state) => state.lang);
    const customer = useSelector((state) => state.customer);

    const [visible, setVisible] = useState(false);
    const [links, setLinks] = useState([]);
    const [isChild, setIsChild] = useState(false);
    const [menuTitle, setMenuTitle] = useState(initialMenuTitle);
    const [menuSlug, setMenuSlug] = useState("");

    // Function to handle show drawer
    const showDrawer = () => {
        setIsChild(false);
        setVisible(true);
        setMenuTitle(initialMenuTitle)
    };
    // Function to handle drawer close
    const onClose = () => {
        setVisible(false);
        setIsChild(false);
        setMenuTitle(initialMenuTitle)
    };

    const showSubElement2 = (parent, subMenus) => {
        if (subMenus.length > 0) {
            setIsChild(true);
            setLinks(subMenus)
            setMenuTitle({ ...menuTitle, title_fr: parent.title_fr, title_en: parent.title_en })
            setMenuSlug(parent.slug)
        }
    };

    // Function to go back to main menu
    const backToMain = () => {
        setLinks([]);
        setIsChild(false);
    };

    // Detecting Length for Last Element No Under Border
    const linksLength = links && links.length;

    return (
        <>
            <Button className="barsMenu mt-1" style={{ paddingTop: '0px' }} size="large" type="primary" onClick={showDrawer}>
                <FiMenu className="barsBtn" />
            </Button>
            <Drawer
                width={320}
                placement="left"
                closable={true}
                onClose={onClose}
                visible={visible}
            >
                <div className="navbar__drawer">
                    <div className="navbar__drawer-user">
                        <h3 className="navbar__drawer-user-heading d-inline-flex ">
                            <FaUserCircle style={{ marginTop: 6, marginRight: 10 }} />
                            <span style={{ marginTop: 0 }}>
                                {translate('header_hello', language)}, {customer ? customer.firstName : translate('header_sigin', language)}
                            </span>
                        </h3>
                    </div>
                    <div className="navbar__drawer-links" style={{ marginBottom: '2rem' }}>
                        <>
                            <div className="menu__back-selector">
                                {isChild ? (
                                    <Fade right duration={500}>
                                        <div
                                            className="navbar__drawer-link d-flex cursor-pointer-set"
                                            onClick={backToMain}
                                            style={{ fontWeight: "900", fontSize: "1.6rem" }}
                                        >
                                            <LeftOutlined style={{ color: 'rgba(0,0,0,0.7)' }} />
                                            &nbsp;&nbsp;&nbsp;{" "}
                                            <h3 style={{ marginBottom: "0px", fontWeight: 'bold', fontFamily: 'Arial', fontSize: '16px' }} >
                                                {translate("principalMenu", language)}
                                            </h3>
                                        </div>
                                        <div style={{ borderBottom: '1px solid #d5dbdb' }} />
                                    </Fade>
                                ) : (
                                    <></>
                                )}
                            </div>
                            {
                                //Links Logic                          
                            }
                            {!isChild && menuGroups && menuGroups.map((menuGroup, index) => {
                                return (
                                    <Fade left={!isChild} right duration={500} className="all-links__container" key={menuGroup._id} >
                                        <>
                                            <h2 key={menuGroup._id} className="navbar__drawer-heading" style={{ fontWeight: '600', fontFamily: 'Arial' }}>
                                                {menuGroup['title_' + language]}
                                            </h2>
                                            {menuGroup.menus.map((menu) => {
                                                return (
                                                    <div key={menu._id} onClick={!menu.withSubMenus ? onClose : () => { }}>
                                                        {menu.external === true ?
                                                            (<a href={menu.link} target="_blank" rel="noopener noreferrer" className="d-flex flex-align-center navbar__drawer-link">
                                                                {menu.icon ? (
                                                                    <span
                                                                        className="icon"
                                                                        style={{
                                                                            width: "22px",
                                                                            alignSelf: "center",
                                                                            display: "inline-flex",
                                                                        }}
                                                                    >
                                                                        {displayIcon(menu.icon)}
                                                                    </span>
                                                                ) : null}
                                                                <span className="title">{menu['title_' + language]}</span>
                                                            </a>) :
                                                            menu.withSubMenus === false ? (
                                                                <div key={menu._id} onClick={onClose}>
                                                                    <Link
                                                                        to={`/${menu.slug}`}
                                                                        className="d-flex flex-align-center navbar__drawer-link"
                                                                    >
                                                                        {menu.icon ? (
                                                                            <span
                                                                                className="icon"
                                                                                style={{
                                                                                    width: "22px",
                                                                                    alignSelf: "center",
                                                                                    display: "inline-flex",
                                                                                }}
                                                                            >
                                                                                {displayIcon(menu.icon)}
                                                                            </span>
                                                                        ) : null}
                                                                        <span className="title">{menu['title_' + language]}</span>
                                                                    </Link>
                                                                </div>

                                                            ) : (
                                                                <div
                                                                    className="navbar__drawer-link d-flex justify-space-between cursor-pointer-set"
                                                                    onClick={() => {
                                                                        showSubElement2(menu, menu.subMenus);
                                                                    }}
                                                                >
                                                                    <span className="title">{menu['title_' + language]}</span>
                                                                    <BiChevronRight style={{ fontSize: '20px', color: 'rgba(0,0,0,0.6)' }} />
                                                                </div>
                                                            )}
                                                    </div>
                                                );
                                            }
                                            )}
                                            {
                                                // Do not show divider for last element 
                                            }
                                            {linksLength === index + 1 ? null : (
                                                <div className="pt-2" style={{ borderBottom: '1px solid #d5dbdb' }} />
                                            )}
                                        </>
                                    </Fade>
                                );
                            })}
                            {isChild && (
                                <Fade left={!isChild} right duration={500} className="all-links__container" // key={subMenus._id} 
                                >
                                    <>
                                        <h2
                                            className="navbar__drawer-heading" style={{ fontWeight: '600', fontFamily: 'Arial' }}>
                                            {
                                                menuTitle['title_' + language]
                                            }
                                        </h2>
                                        {
                                            links.map((smenu) => (
                                                <div key={smenu._id} onClick={smenu.slug ? onClose : () => { }}>
                                                    <Link
                                                        to={`/categories/${menuSlug}/${smenu.slug}`}
                                                        className="d-flex flex-align-center navbar__drawer-link"
                                                    >
                                                        <span className="title">{smenu['title_' + language]}</span>
                                                    </Link>
                                                </div>
                                            )
                                            )
                                        }
                                    </>
                                </Fade>
                            )
                            }

                            {/* OLD MENU SYSTEM
                            <>
                            <div className="menu__back-selector">
                                {isChild ? (
                                    <Fade right duration={500}>
                                        <div
                                            className="navbar__drawer-link d-flex cursor-pointer-set"
                                            onClick={backToMain}
                                            style={{ fontWeight: "900", fontSize: "1.6rem" }}
                                        >
                                            <LeftOutlined style={{ color: 'rgba(0,0,0,0.7)' }} />
                                            &nbsp;&nbsp;&nbsp;{" "}
                                            <h3 style={{ marginBottom: "0px", fontWeight: 'bold', fontFamily: 'Arial', fontSize: '16px' }} >
                                                Menu Principal
                                            </h3>
                                        </div>
                                        <div style={{ borderBottom: '1px solid #d5dbdb' }} />
                                    </Fade>
                                ) : (
                                    <></>
                                )}
                            </div>
                            { 
                                //Links Logic                          
                            }
                            {!isChild && mainLinks && mainLinks.map((el, index) => {
                                return (
                                    <Fade left={!isChild} right duration={500} className="all-links__container" key={el._id} >
                                        <>
                                            <h2 key={el._id} className="navbar__drawer-heading" style={{ fontWeight: '600', fontFamily: 'Arial' }}>
                                                {el['title_' + language]}
                                            </h2>
                                            {el.menusubgroups.map((menusubgroup) => {
                                                return (
                                                    <div key={menusubgroup._id} onClick={menusubgroup.link ? onClose : () => { }}>
                                                        {menusubgroup.external === true ?
                                                            (<a href={menusubgroup.link} target="_blank" rel="noopener noreferrer" className="d-flex flex-align-center navbar__drawer-link">
                                                                {menusubgroup.icon ? (
                                                                    <span
                                                                        className="icon"
                                                                        style={{
                                                                            width: "22px",
                                                                            alignSelf: "center",
                                                                            display: "inline-flex",
                                                                        }}
                                                                    >
                                                                        {displayIcon(menusubgroup.icon)}
                                                                    </span>
                                                                ) : null}
                                                                <span className="title">{menusubgroup['title_' + language]}</span>
                                                            </a>) :
                                                            menusubgroup.categories.length === 0 ? (
                                                                <div key={menusubgroup._id} onClick={onClose}>
                                                                    <Link
                                                                        to={`/${menusubgroup.link}`}
                                                                        className="d-flex flex-align-center navbar__drawer-link"
                                                                    >
                                                                        {menusubgroup.icon ? (
                                                                            <span
                                                                                className="icon"
                                                                                style={{
                                                                                    width: "22px",
                                                                                    alignSelf: "center",
                                                                                    display: "inline-flex",
                                                                                }}
                                                                            >
                                                                                {displayIcon(menusubgroup.icon)}
                                                                            </span>
                                                                        ) : null}
                                                                        <span className="title">{menusubgroup['title_' + language]}</span>
                                                                    </Link>
                                                                </div>

                                                            ) : (
                                                                <div
                                                                    className="navbar__drawer-link d-flex justify-space-between cursor-pointer-set"
                                                                    onClick={() => {
                                                                        showSubElement2(menusubgroup.categories);
                                                                    }}
                                                                >
                                                                    <span className="title">{menusubgroup['title_' + language]}</span>
                                                                    <BiChevronRight style={{ fontSize: '20px', color: 'rgba(0,0,0,0.6)' }} />
                                                                </div>
                                                            )}
                                                    </div>
                                                );
                                            }
                                            )}

                                            { 
                                                // Do not show divider for last element 
                                            }
                                            {linksLength === index + 1 ? null : (
                                                <div style={{ borderBottom: '1px solid #d5dbdb' }} />
                                            )}
                                        </>
                                    </Fade>
                                );
                            })}
                            {isChild && links.map((cat, index) => {
                                return (
                                    <Fade left={!isChild} right duration={500} className="all-links__container" key={cat._id} >
                                        <>
                                            <h2 key={cat._id} className="navbar__drawer-heading" style={{ fontWeight: '600', fontFamily: 'Arial' }}>
                                                {cat['name_' + language]}
                                            </h2>
                                            {cat.subs.map((sub) => {
                                                return (
                                                    <div key={sub._id} onClick={sub.slug ? onClose : () => { }}>
                                                        <Link
                                                            to={`/categorie/${cat.slug}/${sub.slug}`}
                                                            className="d-flex flex-align-center navbar__drawer-link"
                                                        >
                                                            <span className="title">{sub['name_' + language]}</span>
                                                        </Link>
                                                    </div>
                                                );
                                            }
                                            )}

                                            {
                                                // Do not show divider for last element 
                                            }
                                            {linksLength === index + 1 ? null : (
                                                <div style={{ borderBottom: '1px solid #d5dbdb' }} />
                                            )}
                                        </>
                                    </Fade>
                                );
                            })}
                        </> */}
                        </>
                    </div>
                </div>
            </Drawer>
        </>
    )
}

export default MenuDrawer;