//let initialState = []
let initialState = ""
//load cart items from local storage
if (typeof window !== 'undefined') {
    if (localStorage.getItem('localExpiryDate')) {
        initialState = JSON.parse(localStorage.getItem('localExpiryDate'))
    } else {
        initialState = ""
    }
}

const localExpiryDateReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ADD_TO_LOCAL_EXPIRYDATE":
            return action.payload;
        default:
            return state;
    }
}
export default localExpiryDateReducer;