import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import Loadable from 'react-loadable';
import { getCurrentCountry } from './functions/country';
import { getMenugroups } from './functions/menugroup'
import Header from './components/Header';
import Footer from './components/Footer';
import { GeneralLoading } from './components/General/LoadingSpinner';
import { useDispatch } from 'react-redux';
import PrivateRoute from './components/auth/PrivateRoute'
import SideDrawer from './components/drawer/SideDrawer'
import DrawerLogin from './pages/Customer/authCustomer/DrawerLogin'


const Home = Loadable({
    loader: () => import('./pages/Home'),
    loading: GeneralLoading
})
const Cart = Loadable({
    loader: () => import('./pages/Cart'),
    loading: GeneralLoading
})
const Checkout = Loadable({
    loader: () => import('./pages/Checkout'),
    loading: GeneralLoading
})

const Product = Loadable({
    loader: () => import('./pages/Product'),
    loading: GeneralLoading
})

const AllCategories = Loadable({
    loader: () => import('./pages/Category/AllCategories'),
    loading: GeneralLoading
})

const SubCategoryProducts = Loadable({
    loader: () => import('./pages/Category/SubCategory/'),
    loading: GeneralLoading
})


const Search = Loadable({
    loader: () => import('./pages/Search'),
    loading: GeneralLoading
})


// -- CUSTOMER --
const Login = Loadable({
    loader: () => import('./pages/Customer/authCustomer/Login'),
    loading: GeneralLoading
})
const Registration = Loadable({
    loader: () => import('./pages/Customer/authCustomer/Register'),
    loading: GeneralLoading
})
const RegistrationComplete = Loadable({
    loader: () => import('./pages/Customer/authCustomer/RegisterComplete'),
    loading: GeneralLoading
})
const ForgotPassword = Loadable({
    loader: () => import('./pages/Customer/authCustomer/ForgotPassword'),
    loading: GeneralLoading
})
const ForgotValidation = Loadable({
    loader: () => import('./pages/Customer/authCustomer/ForgotValidation'),
    loading: GeneralLoading
})
const ResetPassword = Loadable({
    loader: () => import('./pages/Customer/authCustomer/ResetPassword'),
    loading: GeneralLoading
})

const Dashboard = Loadable({
    loader: () => import('./pages/Customer/CustomerPages/Dashboard'),
    loading: GeneralLoading
})
const AccountProfile = Loadable({
    loader: () => import('./pages/Customer/CustomerPages/AccountProfile'),
    loading: GeneralLoading
})
const Orders = Loadable({
    loader: () => import('./pages/Customer/CustomerPages/Orders'),
    loading: GeneralLoading
})
const Returns = Loadable({
    loader: () => import('./pages/Customer/CustomerPages/Returns'),
    loading: GeneralLoading
})

/* const SingleOrder = Loadable({
    loader: () => import('./pages/Customer/CustomerPages/SingleOrder'),
    loading: GeneralLoading
}) */
const Favorites = Loadable({
    loader: () => import('./pages/Customer/CustomerPages/Favorites'),
    loading: GeneralLoading
})
const DanixtCard = Loadable({
    loader: () => import('./pages/Customer/CustomerPages/DanixtCard'),
    loading: GeneralLoading
})

/* const GiftCard = Loadable({
    loader: () => import('./pages/GiftCard'),
    loading: GeneralLoading
})
const GiftCardCheckout = Loadable({
    loader: () => import('./pages/GiftCard/GiftCardCheckout'),
    loading: GeneralLoading
}) */
// -- CUSTOMER --

// -- BOUTIQUE --
const Boutiques = Loadable({
    loader: () => import('./pages/Boutique/'),
    loading: GeneralLoading
})
const SingleBoutique = Loadable({
    loader: () => import('./pages/Boutique/SingleBoutique'),
    loading: GeneralLoading
})
const SingleBoutiqueSub = Loadable({
    loader: () => import('./pages/Boutique/SingleBoutiqueSub'),
    loading: GeneralLoading
})

// -- BOUTIQUE --


// -- MARQUES --
const Brands = Loadable({
    loader: () => import('./pages/Brands/'),
    loading: GeneralLoading
})
const SingleBrand = Loadable({
    loader: () => import('./pages/Brands/SingleBrand'),
    loading: GeneralLoading
})

// -- MARQUES --

// -- PROMOTIONS --
const Promotions = Loadable({
    loader: () => import('./pages/Promotions'),
    loading: GeneralLoading
})
// -- PROMOTIONS --

// -- HELP --
const Faq = Loadable({
    loader: () => import('./pages/HelpCenter/Faq'),
    loading: GeneralLoading
})

const SingleHelp = Loadable({
    loader: () => import('./pages/HelpCenter/SingleHelp'),
    loading: GeneralLoading
})
// -- HELP --

// -- POLICY --
const Privacy = Loadable({
    loader: () => import('./pages/Documentation/Privacy'),
    loading: GeneralLoading
})
const LegalNotice = Loadable({
    loader: () => import('./pages/Documentation/LegalNotice'),
    loading: GeneralLoading
})
const ReturnPolicy = Loadable({
    loader: () => import('./pages/Documentation/ReturnPolicy'),
    loading: GeneralLoading
})
const Terms = Loadable({
    loader: () => import('./pages/Documentation/Terms'),
    loading: GeneralLoading
})
// -- POLICY --

// -- RESSOURCES --
const Contact = Loadable({
    loader: () => import('./pages/Contact'),
    loading: GeneralLoading
})
const ResourcesPromotions = Loadable({
    loader: () => import('./pages/Documentation/Promotions'),
    loading: GeneralLoading
})
const GiftCards = Loadable({
    loader: () => import('./pages/Documentation/GiftCards'),
    loading: GeneralLoading
})
/* const ResourcesDeliveryPoints = Loadable({
    loader: () => import('./pages/Documentation/DeliveryPoints'),
    loading: GeneralLoading
}) */
const SellerGuide = Loadable({
    loader: () => import('./pages/Documentation/SellerGuide'),
    loading: GeneralLoading
})

// -- POLICY --


const FourZeroFour = Loadable({
    loader: () => import('./pages/FourZeroFour'),
    loading: GeneralLoading
})

const App = () => {
    const dispatch = useDispatch();
    const [menuGroups, setMenuGroups] = useState([])

    useEffect(() => {
        const loadCountry = () => {
            getCurrentCountry(process.env.REACT_APP_COUNTRY)
                .then((res) => {
                    localStorage.setItem('localCountry', JSON.stringify(res.data))
                    //add to redux state
                    dispatch({
                        type: "ADD_TO_LOCALCOUNTRY",
                        payload: res.data,
                    })
                })
                .catch((err) => {
                    localStorage.setItem('localCountry', JSON.stringify({}))
                })
        }

        const loadMenuGroup = () => {
            getMenugroups().then((res) => {
                setMenuGroups(res.data)
                //console.log('Menusgroup', res.data)
            })
        }

        loadMenuGroup()
        loadCountry()
    }, [dispatch])

    return (
        <>
            <Header menuGroups={menuGroups} />
            <SideDrawer />
            <DrawerLogin />
            <div style={{ minHeight: '80vh', backgroundColor: '#fff' }}>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/accueil" component={Home} />
                    <Route exact path="/cart" component={Cart} />
                    <Route exact path="/categories/" component={AllCategories} />
                    <Route exact path="/categorie/" component={AllCategories} />
                    <Route exact path="/categorie/:slug" component={AllCategories} />
                    <Route exact path="/categories/:slug" component={AllCategories} />
                    {/* <Route exact path="/articles/" component={AllCategories} />
                <Route exact path="/article/" component={AllCategories} />
                <Route exact path="/articles/:slug" component={AllCategories} />
                <Route exact path="/article/:slug" component={AllCategories} /> */}

                    <Route exact path="/search" component={Search} />

                    <Route exact path="/categories/:menuSlug/:slug/:page" component={SubCategoryProducts} />
                    <Route exact path="/categories/:menuSlug/:slug/" component={SubCategoryProducts} />
                    <Route exact path="/categorie/:menuSlug/:slug/:page" component={SubCategoryProducts} />
                    <Route exact path="/categorie/:menuSlug/:slug/" component={SubCategoryProducts} />
                    {/* <Route exact path="/articles/:menuSlug/:slug/:page" component={SubCategoryProducts} />
                <Route exact path="/article/:menuSlug/:slug/:page" component={SubCategoryProducts} /> */}

                    <Route exact path="/articles/details/:slug/:_id" component={Product} />
                    <Route exact path="/article/details/:slug/:_id" component={Product} />
                    {/* <Route exact path="/giftcard" component={GiftCard} />
                <Route exact path="/giftcards" component={GiftCard} />
                <Route exact path="/cadeaux" component={GiftCard} />
                <Route exact path="/cartes-cadeaux" component={GiftCard} />
                <Route exact path="/giftcard-checkout" component={GiftCardCheckout} /> */}
                    {/* --BOUTIQUES -- */}
                    <Route exact path="/boutiques" component={Boutiques} />
                    <Route exact path="/boutique" component={Boutiques} />
                    <Route exact path="/boutiques/:idCode" component={SingleBoutique} />
                    <Route exact path="/boutique/:idCode" component={SingleBoutique} />
                    <Route exact path="/boutiques/:idCode/:subSlug/:page" key="b1" component={SingleBoutiqueSub} />
                    <Route exact path="/boutique/:idCode/:subSlug/:page" key="b2" component={SingleBoutiqueSub} />
                    <Route exact path="/boutiques/:idCode/:subSlug" key="b3" component={SingleBoutiqueSub} />
                    <Route exact path="/boutique/:idCode/:subSlug" key="b4" component={SingleBoutiqueSub} />
                    {/* BRANDS */}
                    <Route exact path="/marques" component={Brands} />
                    <Route exact path="/marque" component={Brands} />
                    <Route exact path="/brands" component={Brands} />
                    <Route exact path="/brand" component={Brands} />
                    <Route exact path="/marques/:slug/:page" component={SingleBrand} />
                    <Route exact path="/marque/:slug/:page" component={SingleBrand} />
                    <Route exact path="/brands/:slug/:page" component={SingleBrand} />
                    <Route exact path="/brand/:slug/:page" component={SingleBrand} />
                    <Route exact path="/marques/:slug" key="m11" component={SingleBrand} />
                    <Route exact path="/marque/:slug" key="m22" component={SingleBrand} />
                    <Route exact path="/brands/:slug" key="m13" component={SingleBrand} />
                    <Route exact path="/brand/:slug" key="m24" component={SingleBrand} />
                    {/* --CUSTOMER -- */}
                    <PrivateRoute exact path="/checkout" component={Checkout} />
                    <PrivateRoute exact path="/moncompte" component={Dashboard} />
                    <PrivateRoute exact path="/monprofil" component={AccountProfile} />
                    <PrivateRoute exact path="/commandes/:orderlabel" component={Orders} />
                    <PrivateRoute exact path="/commandes" component={Orders} />
                    {/* <PrivateRoute exact path="/commande/:_id" component={SingleOrder} /> */}
                    <PrivateRoute exact path="/retours" component={Returns} />
                    <PrivateRoute exact path="/favoris" component={Favorites} />
                    <PrivateRoute exact path="/carte-danixt" component={DanixtCard} />
                    {/* --PROMOTIONS COUPONS -- */}
                    <Route exact path="/coupons" component={Promotions} />
                    <Route exact path="/coupon" component={Promotions} />
                    <Route exact path="/promotions" component={Promotions} />
                    <Route exact path="/promotion" component={Promotions} />
                    {/* -- HELP -- */}
                    <Route exact path="/aide" component={Faq} />
                    <Route exact path="/help" component={Faq} />
                    <Route exact path="/faq" component={Faq} />
                    <Route exact path="/help/:slug" component={SingleHelp} />
                    <Route exact path="/aide/:slug" component={SingleHelp} />
                    <Route exact path="/faq/:slug" component={SingleHelp} />
                    {/* -- POLICY -- */}
                    <Route exact path="/politique" component={Privacy} />
                    <Route exact path="/politiques" component={Privacy} />
                    <Route exact path="/politiques/confidentialite" component={Privacy} />
                    <Route exact path="/politiques/mentions-legales" component={LegalNotice} />
                    <Route exact path="/politiques/retours" component={ReturnPolicy} />
                    <Route exact path="/politiques/nos-conditions" component={Terms} />
                    {/* -- RESOURCES -- */}
                    <Route exact path="/contact" component={Contact} />
                    <Route exact path="/ressource" component={SellerGuide} />
                    <Route exact path="/ressources" component={SellerGuide} />
                    <Route exact path="/ressources/promotions" component={ResourcesPromotions} />
                    <Route exact path="/ressources/carte-danixt" component={GiftCards} />
                    {/* <Route exact path="/ressources/livraison" component={ResourcesDeliveryPoints} /> */}
                    <Route exact path="/ressources/vendeurs" component={SellerGuide} />
                    {/* --AUTH -- */}
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/register" component={Registration} />
                    <Route exact path="/register/complete/:token" component={RegistrationComplete} />
                    <Route exact path="/forgot-password" component={ForgotPassword} />
                    <Route exact path="/forgot-validation/:_id" component={ForgotValidation} />
                    <Route exact path="/reset-password/:_id" component={ResetPassword} />
                    {/* --404 -- */}
                    <Route component={FourZeroFour} />
                </Switch>
            </div>

            {/* <ScrollTop /> */}
            <Footer />
        </>
    );
}

export default App;
