let initialState = []

//load historical items from local storage
if (typeof window !== 'undefined') {
    if (localStorage.getItem('historical')) {
        initialState = JSON.parse(localStorage.getItem('historical'))
    } else {
        initialState = []
    }
}

const historicalReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ADD_TO_HISTORICAL":
            return action.payload;
        default:
            return state;
    }
}
export default historicalReducer;