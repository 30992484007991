import axios from "axios";

export const getCountries = async () =>
    await axios.get(`${process.env.REACT_APP_API}/countries`);

export const getCountryCities = async (_id) =>
    await axios.get(`${process.env.REACT_APP_API}/country/cities/${_id}`);

export const getPointsByDistrcit = async (_id) =>
    await axios.get(`${process.env.REACT_APP_API}/collectpoints/${_id}`);

export const getCurrentCountry = async (domain) =>
    await axios.get(`${process.env.REACT_APP_API}/current-country/${domain}`);
