import React from "react";
import { Row, Col, Typography, Select } from "antd";
import { GlobalOutlined } from "@ant-design/icons";
import {
  Link//, useLocation 
} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { translate } from '../../i18n';
import { setLanguage } from '../../store/actions/langActions';
import BottomNavBar from './BottomNavBar';
//import BottomProduct from './BottomProduct';
import "./Footer.scss";
import ModalCountry from '../Modal/ChooseCountry';

import visa from '../../images/paymentIcons/visa.png'
import wave from '../../images/paymentIcons/wave.png'
import mtn from '../../images/mtnCard.png'
import orange from '../../images/orangeCard.png'
import mastercard from '../../images/paymentIcons/mastercard.png'
import maestro from '../../images/paymentIcons/maestro.png'
import Logo from '../../images/LogoDanixt-cm.png';

const { Option } = Select;

const marginRight = {
  marginRight: 30
}

const Footer = () => {
  const { language } = useSelector((state) => state.lang);
  const dispatch = useDispatch();
  // const location = useLocation();
  // const [visible, setVisible] = useState(true)
  // const [prodId, setProdId] = useState("")

  const chhoseLanguageHandler = value => {
    dispatch(setLanguage(value));
  }
  /* 
    useEffect(() => {
      let lien = window.location.href.split('/') && window.location.href.split('/')
      // const prodId = lien.slice(-1).pop()
      console.log('window.location.href', lien.slice(-1).pop())
      if (window.location.href.split('/').includes('details')) {
        //console.log('visible false')
        setVisible(false)
      } else {
        //console.log('visible true')
        setProdId(lien.slice(-1).pop())
        setVisible(true)
      }
    }, [location])
   */


  return (
    <>
      <div className="footer">
        <div className="footer__content">
          <Row>
            <Col xl={0} lg={0} md={24} sm={24} xs={24}>
              <Row
                align="top"
                justify="space-between"
                style={{ marginBottom: "0px !important" }}
                gutter={[15, 25]}
                className="footer__content-links"
              >
                <Col xs={12} sm={12} md={12} lg={0} xl={0} xxl={0} className="footer__content-links-list" >
                  <Typography.Title
                    level={4}
                    className="footer__content-links-list-title"
                  >
                    {translate('footerT4', language)}
                  </Typography.Title>
                  <div className="footer__content-links-list-link-container">
                    <Link to="/politiques/confidentialite" className="footer__content-links-list-link-container-link">
                      {translate('footerL41', language)}
                    </Link>
                    <Link to="/politiques/mentions-legales" className="footer__content-links-list-link-container-link">
                      {translate('footerL42', language)}
                    </Link>
                    <Link to="/politiques/retours" className="footer__content-links-list-link-container-link" >
                      {translate('footerL43', language)}
                    </Link>
                    <Link to="/politiques/nos-conditions" className="footer__content-links-list-link-container-link" >
                      {translate('footerL44', language)}
                    </Link>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={0} xl={0} xxl={0} className="footer__content-links-list" >
                  <Typography.Title
                    level={4}
                    className="footer__content-links-list-title"
                  >
                    {translate('footerT2', language)}
                  </Typography.Title>
                  <div className="footer__content-links-list-link-container">
                    <Link to="/faq" className="footer__content-links-list-link-container-link" >
                      {translate('footerT3', language)}
                    </Link>
                    <Link to="/ressources/carte-danixt" className="footer__content-links-list-link-container-link" >
                      {translate('footerL22', language)}
                    </Link>
                    <Link to="/ressources/vendeurs" className="footer__content-links-list-link-container-link" >
                      {translate('footerL24', language)}
                    </Link>
                    <a href="https://danixt.com" target="danixtCom" rel="noreferrer" className="footer__content-links-list-link-container-link">
                      {translate('footerL14', language)}
                    </a>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xl={24} lg={24} md={0} sm={0} xs={0}>
              <Row
                align="top"
                justify="space-between"
                style={{ marginBottom: "0px !important" }}
                gutter={[15, 25]}
                className="footer__content-links"
              >
                <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6} className="footer__content-links-list" >
                  <Typography.Title
                    level={4}
                    className="footer__content-links-list-title"
                  >
                    {translate('footerT4', language)}
                  </Typography.Title>
                  <div className="footer__content-links-list-link-container">
                    <Link to="/politiques/confidentialite" className="footer__content-links-list-link-container-link">
                      {translate('footerL41', language)}
                    </Link>
                    <Link to="/politiques/mentions-legales" className="footer__content-links-list-link-container-link">
                      {translate('footerL42', language)}
                    </Link>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6} className="footer__content-links-list" >
                  <Typography.Title
                    level={4}
                    className="footer__content-links-list-title"
                  >
                    &nbsp;
                  </Typography.Title>
                  <div className="footer__content-links-list-link-container">
                    <Link to="/politiques/retours" className="footer__content-links-list-link-container-link" >
                      {translate('footerL43', language)}
                    </Link>
                    <Link to="/politiques/nos-conditions" className="footer__content-links-list-link-container-link" >
                      {translate('footerL44', language)}
                    </Link>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6} className="footer__content-links-list" >
                  <Typography.Title
                    level={4}
                    className="footer__content-links-list-title"
                  >
                    {translate('footerT2', language)}
                  </Typography.Title>
                  <div className="footer__content-links-list-link-container">
                    <Link to="/faq" className="footer__content-links-list-link-container-link" >
                      {translate('footerT3', language)}
                    </Link>
                    <Link to="/ressources/carte-danixt" className="footer__content-links-list-link-container-link" >
                      {translate('footerL22', language)}
                    </Link>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6} className="footer__content-links-list" >
                  <Typography.Title
                    level={4}
                    className="footer__content-links-list-title"
                  >
                    &nbsp;
                  </Typography.Title>
                  <div className="footer__content-links-list-link-container">
                    <Link to="/ressources/vendeurs" className="footer__content-links-list-link-container-link" >
                      {translate('footerL24', language)}
                    </Link>
                    <a href="https://danixt.com" target="danixtCom" rel="noreferrer" className="footer__content-links-list-link-container-link">
                      {translate('footerL14', language)}
                    </a>
                  </div>
                </Col>
                {/* <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6} className="footer__content-links-list" >
                  <Typography.Title
                    level={4}
                    className="footer__content-links-list-title"
                  >
                    {translate('footerT1', language)}
                  </Typography.Title>
                  <div className="footer__content-links-list-link-container">
                    <Link to="/contact" className="footer__content-links-list-link-container-link">
                      {translate('footerL13', language)}
                    </Link>
                    <a href="https://danixt.com" target="danixtCom" rel="noreferrer" className="footer__content-links-list-link-container-link">
                      {translate('footerL14', language)}
                    </a>
                    <a href="https://danixt.com/carriere" target="danixtCom" rel="noreferrer" className="footer__content-links-list-link-container-link">
                      {translate('footerL11', language)}
                    </a>
                    <a href="https://danixt.com/partenariat" target="danixtCom" rel="noreferrer" className="footer__content-links-list-link-container-link" >
                      {translate('footerL12', language)}
                    </a>
                  </div>
                </Col> */}
              </Row>
            </Col>
          </Row>
        </div>
        <div className="footer__country-payment">
          <Row
            align="top"
            justify="space-between"
            gutter={[15, 25]}
            className="footer__country-payment-block1"
          >
            <Col span={24}>
              <Row justify="center" gutter={[0, 0]}>
                <Col xl={12} lg={12} md={24} sm={24} xs={24} className="footer__country-payment-cplogo" >
                  <Row align="bottom" justify="center">
                    <img src={Logo} alt="logo" height="30" className="footerLogo" style={marginRight} />
                    <Select
                      value={language}
                      className="footer__select"
                      clearIcon={<></>}
                      style={marginRight}
                      onChange={chhoseLanguageHandler}
                    >
                      <Option value="en">
                        <Row align="middle" gutter={6}>
                          <Col className="d-inline-flex d-inline-flex-center">
                            <GlobalOutlined style={{ color: '#febd69' }} />
                          </Col>
                          <Col>English</Col>
                        </Row>
                      </Option>
                      <Option value="fr">
                        <Row align="middle" gutter={6}>
                          <Col className="d-inline-flex d-inline-flex-center">
                            <GlobalOutlined style={{ color: '#febd69' }} />
                          </Col>
                          <Col>Français</Col>
                        </Row>
                      </Option>
                    </Select>
                    <Row align="center" justify="space-between">
                      <ModalCountry typeDisplay="footer" />
                    </Row>
                  </Row>
                </Col>
                <Col className="text-center" xl={12} lg={12} md={24} sm={24} xs={24} >
                  <img src={visa} className="footer__country-payment-cpimg" alt="Visa Card" />
                  <img src={mastercard} className="footer__country-payment-cpimg" alt="Master Card" />
                  <img src={maestro} className="footer__country-payment-cpimg" alt="Maestro Card" />
                  <img src={wave} style={{ display: 'none' }} className="footer__country-payment-cpimg" alt="Wave" />
                  <img src={orange} className="footer__country-payment-cpimg" alt="Orange Mobile Money" />
                  <img src={mtn} className="footer__country-payment-cpimg" alt="MTN Mobile Money" />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className="footer__copyright non-computer">
          <Row align="center" gutter={18}>
            <Col>
              <Link to="#" className="footer__copyright-copy">
                &copy; 2022 - Danixt Group
              </Link>
            </Col>
          </Row>
        </div>
        {/*  <div className="non-computer mt-5 pt-2">
          <BottomNavBar />
        </div> */}
        <div className="footer__copyright non-mobile">
          <Row align="center" gutter={18}>
            <Col>
              <a href="http://danixt.com" target="danixtCom" rel="noreferrer" className="footer__copyright-copy">
                &copy; {new Date().getFullYear()} - Danixt Group
              </a>
            </Col>
          </Row>
        </div>
      </div>
      <div className="mt-3 non-computer">
        <BottomNavBar />
      </div>

      {/* {
        visible === true && (
          <div className="mt-3 non-computer">
            <BottomNavBar />
          </div>
        )
      } */}
    </>
  )
}

export default Footer;
