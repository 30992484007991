import { combineReducers } from 'redux';
import langReducer from './reducers/langReducer';
import customerReducer from './reducers/customerReducer';
import cartReducer from './reducers/cartReducer'
import drawerReducer from './reducers/drawerReducer'
import historicalReducer from './reducers/historicalReducer'
import paymentReducer from './reducers/paymentReducer'
import localcatsReducer from './reducers/localcatsReducer'
import localgiftcardReducer from './reducers/localgiftcardReducer'
import localExpiryDateReducer from './reducers/localDateReducer'
import localCountryReducer from './reducers/countryReducer'
import localMenuReducer from './reducers/localMenuReducer'
import loginDrawerReducer from './reducers/loginDrawerReducer'

const rootReducer = combineReducers({
    lang: langReducer,
    customer: customerReducer,
    cart: cartReducer,
    drawer: drawerReducer,
    loginDrawer: loginDrawerReducer,
    paymentStore: paymentReducer,
    historical: historicalReducer,
    localCats: localcatsReducer,
    localGiftCard: localgiftcardReducer,
    localExpiryDate: localExpiryDateReducer,
    localCountry: localCountryReducer,
    localMenus: localMenuReducer,
})


//const store = createStore(rootReducer);

//export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;