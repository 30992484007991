import React from "react";
import "./Header.scss";

import Navbar from "./Navbar";
import SubNavbar from "./SubNavbar";

const NavigationBar = ({ menuGroups }) => {
  return (
    <header style={{ zIndex: '20' }}>
      <Navbar menuGroups={menuGroups} />
      <SubNavbar />
    </header>
  );
}

export default NavigationBar;