import React, { useState, useEffect } from 'react';
import Modal from "react-bootstrap/Modal";
import Icon, { CloseCircleFilled } from '@ant-design/icons';
import { Row, Col, Select, Button } from "antd";
import { useSelector } from 'react-redux';
import { getCountries } from '../../functions/country'
import { translate } from '../../i18n';

import "./Modal.scss"

const { Option } = Select;


const ModalCountry = ({ typeDisplay }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [choosedCountry, setChoosedCountry] = useState(process.env.REACT_APP_COUNTRY)
    const idType = typeDisplay;

    const { language } = useSelector((state) => state.lang);
    const [countries, setCountries] = useState([])
    const currentCountry = useSelector((state) => state.localCountry)

    useEffect(() => {
        loadCountries()
    }, [])

    const loadCountries = () => {
        getCountries()
            .then((res) => {
                setCountries(res.data)
            })
            .catch((err) => {
                setCountries([])
            })
    }

    const showModal = () => {
        setIsOpen(true);
    };

    const hideModal = () => {
        setIsOpen(false);
    };

    const eltClick = () => {
        if (idType === "footer") {
            return (
                <Button className="country-button" onClick={showModal} >
                    <img src={currentCountry && currentCountry.image} alt={currentCountry && currentCountry['name_' + language]} height="12" style={{ marginRight: 5 }} />
                    <span> {currentCountry && currentCountry['name_' + language]}</span>
                </Button>
            )
        } else if (idType === "header") {
            return (
                <div style={{ cursor: 'pointer', fontSize: "15px", color: 'var(--main-green)', fontWeight: 'bold' }} onClick={showModal}>
                    Choisir un autre pays
                </div>
            )
        }
    }

    const chooseCountryHandler = (value) => {
        setChoosedCountry(value)
    }


    return (
        <>
            {
                eltClick()
            }

            <Modal
                show={isOpen} onHide={hideModal}
                size="md"
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header style={{ background: '#eaeded', color: '#000' }}>
                    <h3>{translate('countryModal_title', language)}</h3>
                    <span style={{ cursor: 'pointer', color: '#f08804' }}>
                        <CloseCircleFilled style={{ color: '#f08804', fontSize: '22px' }} onClick={hideModal} />
                    </span>
                </Modal.Header>
                <Modal.Body style={{ background: '#fff', color: '#000', fontSize: '13px' }}>
                    <div className="row">
                        <div className="col-6">
                            <h3 style={{ fontWeight: 'bold' }}>{translate('countryModal_subtitle1', language)}</h3>
                            <div style={{ margin: '15px 5px' }}>
                                <Select style={{ width: '100%' }} defaultValue={currentCountry && currentCountry.domain} clearIcon={<></>} onChange={chooseCountryHandler}>
                                    {
                                        countries && countries.map((c) => (
                                            <Option value={c.domain} key={c._id}>
                                                <Row align="middle" gutter={6}>
                                                    <Col className="d-inline-flex d-inline-flex-center">
                                                        <Icon component={() => (<img
                                                            src={c.image}
                                                            alt="flag"
                                                            style={{ width: "18px", height: "10px" }}
                                                        />)} />
                                                    </Col>
                                                    <Col>{c['name_' + language]}</Col>
                                                </Row>
                                            </Option>
                                        ))
                                    }
                                </Select>
                            </div>
                            <p><span style={{ fontWeight: 'bold' }}>{translate('countryModal_noteTitle', language)}</span> {translate('countryModal_noteContent', language)}</p>

                        </div>
                        <div className="col-6">
                            <h3 style={{ fontWeight: 'bold' }}>{translate('countryModal_subtitle2', language)}</h3>
                            <p>{translate('countryModal_Content2', language)}</p>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Body style={{ background: '#eee', color: '#000', textAlign: 'center' }}>
                    <Row gutter={[20, 0]} justify="center" >
                        <Col>
                            <Button onClick={hideModal}> {translate('cancel', language)}</Button>
                        </Col>
                        <Col>
                            <a href={choosedCountry === process.env.REACT_APP_COUNTRY ? "/" : `http://danixt.${choosedCountry}`}
                                target={choosedCountry === process.env.REACT_APP_COUNTRY ? "_self" : "_blank"}
                            >
                                <Button type="primary">
                                    {choosedCountry ? (<>{translate('countryModal_goTo', language) + choosedCountry}</>) : <>{translate('countryModal_goToWebsite', language)}</>}
                                </Button>
                            </a>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ModalCountry;