export default function validateInfo(values) {
    let errors = {};

    const regExp = RegExp(
        /^([a-zA-Z0-9_.\-]+)@([a-zA-Z0-9_\-]+)\.([a-zA-Z]{2,5})$/
    );

    if (!values.email) {
        errors.email = 'Adresse email requise';
    } else if (!values.email.match(regExp)) {
        errors.email = 'Adresse email invalide';
    }

    if (!values.password) {
        errors.password = 'Mot de passe requis';
    } else if (values.password.length < 6) {
        errors.password = 'Le mot de passe doit contenir au moins 6 caractères';
    }

    return errors;
}