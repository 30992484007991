let initialState = {}

//load cart items from local storage
if (typeof window !== 'undefined') {
    if (localStorage.getItem('localCountry')) {
        initialState = JSON.parse(localStorage.getItem('localCountry'))
    } else {
        initialState = {}
    }
}

const localCountryReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ADD_TO_LOCALCOUNTRY":
            return action.payload;
        default:
            return state;
    }
}
export default localCountryReducer;