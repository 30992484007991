import React, { useState, useEffect } from "react";

import { Link, withRouter, useLocation } from "react-router-dom";

import { Row, Col } from "antd";
import Searcher from './Searcher2'
import RightNavbar from './RightNavbar'
import MenuDrawer from './MenuDrawer'
import "./Header.scss";
import LogoDanixt from '../../images/LogoDanixt-cm.png'


const Navbar = ({ menuGroups }) => {

  const location = useLocation();
  const [visible, setVisible] = useState(true)

  useEffect(() => {
    if (window.location.href.split('/').includes('details')) {
      //console.log('visible false')
      setVisible(false)
    } else {
      //console.log('visible true')
      setVisible(true)
    }
  }, [location])

  return (
    <>
      <Row gutter={[10, 0]} justify="start" align="middle" style={{ margin: '10px !important' }} className="menu__container pb-2" >
        <Col xl={4} lg={5} md={8} sm={8} xs={10} order={0} className="d-flex">
          <MenuDrawer menuGroups={menuGroups} />
          <Link to="/" className="menu__logo pb-0">
            <img src={LogoDanixt} alt="logo" className="pt-1 mb-1" />
          </Link>
        </Col>
        <Col xl={8} lg={10} md={16} sm={16} xs={14} className="pl-xl-2 order-lg-2 order-md-1 order-sm-1 order-xs-1">
          <RightNavbar />
        </Col>
        <Col xl={12} lg={9} md={24} sm={visible === true ? 24 : 0} xs={visible === true ? 24 : 0} className="pl-xl-3 pr-xl-3 pl-lg-1 pr-lg-1 order-lg-1 order-md-2 order-sm-2 order-xs-2" >
          <Searcher />
        </Col>
      </Row>
    </>
  );
};

export default withRouter(Navbar);
