import React from 'react';
import { Spin } from 'antd';

export const GeneralLoading = () => {
    return (
        <div style={{ textAlign: 'center', marginTop: '150px', marginBottom: '200px' }}>
            <Spin tip="Loading ... " size="large" />
        </div>
    )
}

export const Siner = () => {
    return (
        <div style={{ textAlign: 'center' }}>
            <Spin size="medium" />
        </div>
    )
}



