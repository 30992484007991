import React from "react";
import { Col, Row, Typography } from "antd";
import { useSelector } from 'react-redux';
import { HomeOutlined } from "@ant-design/icons";
import "./Header.scss";
import { Link } from "react-router-dom";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { translate } from '../../i18n';

const SubNavbar = () => {
  const isMobile = useWindowDimensions().width <= 989;
  const { language } = useSelector((state) => state.lang);

  return (
    <>
      {
        !isMobile && (
          <Row
            justify="space-between"
            align="middle"
            style={{
              marginTop: "0px",
              marginBottom: '0px !important',
              background: "#232f3e",
              color: "#fff",
              minHeight: '40px'
            }}
            gutter={[0, 10]}
          >
            <Col>
              <div
                className="cursor-pointer-set set-outline"
                style={{ width: "170px", paddingLeft: '1rem', marginLeft: '0.6rem' }}
              >
                <Link to="/">
                  <Row align="middle">
                    <Col>
                      <span className="location__icon">
                        <HomeOutlined
                          style={{
                            color: "#fff",
                            marginRight: "10px",
                            fontSize: "20px",
                          }}
                        />
                      </span>
                    </Col>
                    <Col>
                      <Typography.Title level={5} className="navbar__bottom-text-medium">
                        {translate("home", language)}
                      </Typography.Title>
                    </Col>
                  </Row>
                </Link>
              </div>
            </Col>
            <Col
              style={{ marginLeft: "5px", padding: "0rem 0.5rem" }}
              className="navbar__links-container"
            >
              <span className="navbar__single-link-container set-outline cursor-pointer-set">
                <Link to="/promotions" className="navbar__link">
                  Promotions
                </Link>
              </span>
              <span className="navbar__single-link-container set-outline cursor-pointer-set">
                <Link to="/categories" className="navbar__link">
                  {translate("categories", language)}
                </Link>
              </span>
              {/* <span className="navbar__single-link-container set-outline cursor-pointer-set">
                <Link to="/cartes-cadeaux" className="navbar__link">
                  {translate("header_gifcardBold", language)}
                </Link>
              </span> */}
              <span className="navbar__single-link-container set-outline cursor-pointer-set">
                <Link to="/boutiques" className="navbar__link">
                  {translate("allStores", language)}
                </Link>
              </span>
              {/* <span className="navbar__single-link-container set-outline cursor-pointer-set">
                <Link to="/marques" className="navbar__link">
                  {translate("titleAllBrands", language)}
                </Link>
              </span> */}
              <span className="navbar__single-link-container set-outline cursor-pointer-set">
                <Link to="/aide" className="navbar__link">
                  {translate("help", language)}
                </Link>
              </span>
              <span className="navbar__single-link-container set-outline cursor-pointer-set">
                <Link to="/contact" className="navbar__link">
                  Contact
                </Link>
              </span>
              {/* <span className="navbar__single-link-container set-outline cursor-pointer-set">
                      <a href="https://seller.danixt.cm" target="danixtSellerCI" rel="noreferrer" className="navbar__link">
                        <span className="location__icon">
                          <ShopOutlined
                            style={{
                              color: "#fff",
                              marginRight: "5px",
                              marginLeft: "10px",
                              fontSize: "18px",
                            }}
                          />
                        </span>Devenir vendeur
                      </a>
                    </span> 
                    <span className="navbar__single-link-container set-outline cursor-pointer-set">
                      <a href="https://danixt.tv" target="danixtTV" rel="noreferrer" className="navbar__link">
                        Danixt.tv
                      </a>
                    </span>
                    */}
            </Col>
          </Row>
        )
      }
    </>

  );
};

export default SubNavbar;
