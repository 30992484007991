import en from './en.json';
import fr from './fr.json';

export const translate = (key: string, language: string): string => {
    let langData: { [key: string]: string } = {};
    if (language === 'en') {
        langData = en;
    } else {
        langData = fr
    }
    return langData[key];
}