import React from 'react';
import ReactDOM from 'react-dom';
//import { composeWithDevTools } from 'redux-devtools-extension';
import { BrowserRouter } from 'react-router-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import rootReducder from './store/index.js';
import App from './App';

import * as serviceWorker from './serviceWorker';
import "./styles/Main.less";

//const store = createStore(rootReducder, composeWithDevTools())
const store = createStore(rootReducder)

console.warn = () => { }

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter >
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
