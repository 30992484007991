import axios from 'axios'

export const startRegister = async (customer) =>
    await axios.post(`${process.env.REACT_APP_API}/register`, customer);

export const completeRegister = async (token) => {
    const result = await axios.post(`${process.env.REACT_APP_API}/register/activate`,
        { token });
    return result;
}

export const login = async (email, password) => {
    return axios.post(`${process.env.REACT_APP_API}/login`,
        { email, password }
    );
}

export const forgotPassword = async (email) => {
    return axios.post(`${process.env.REACT_APP_API}/forgot-password`,
        { email }
    );
}

export const getForgotCustomer = async (_id) =>
    await axios.get(`${process.env.REACT_APP_API}/customer-forgot/${_id}`);

export const updateForgotCustomer = async (_id) =>
    await axios.put(`${process.env.REACT_APP_API}/update-customer-forgot/${_id}`);

export const resetPassword = async (_id, password) =>
    await axios.put(`${process.env.REACT_APP_API}/update-customer-password/${_id}`, { password });

/* export const forgotPassword = async (email) => {
    const result = await axios.put(`${process.env.REACT_APP_API}/forgot-password`,
        { email });
    return result;
}

export const resetPassword = async (token, password) => {
    const result = await axios.put(`${process.env.REACT_APP_API}/reset-password`,
        { resetPasswordLink: token, newPassword: password });
    return result;
} */


