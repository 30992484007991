import React from 'react'
import { Alert } from 'antd';

export const showSuccessMessage = (title, message) => <Alert
    message={title}
    description={message}
    type="success"
    showIcon
/>;
export const showErrorMessage = (title, message) => <Alert
    message={title}
    description={message}
    type="error"
    showIcon
/>;
