let initialState = []

//load cart items from local storage
if (typeof window !== 'undefined') {
    if (localStorage.getItem('localCats')) {
        initialState = JSON.parse(localStorage.getItem('localCats'))
    } else {
        initialState = []
    }
}

const localcatsReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ADD_TO_LOCALCATS":
            return action.payload;
        default:
            return state;
    }
}
export default localcatsReducer;