import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { translate } from '../../i18n';

import "./Header.scss";
import "./Searcher.scss"

const Searcher = () => {
    const { language } = useSelector((state) => state.lang);
    const [painText, setPainText] = useState("")
    const url1 = window.location.href.split('?')[0]
    const currentPage = url1.split('/')[3]

    useEffect(() => {
        const abcd = () => {
            if (currentPage === "search") {
                var url = new URL(window.location.href);
                var q = url.searchParams.get("q");
                setPainText(q)
                //console.log('page', window.location.href.split('/'))
            } else {
                setPainText("")
                //console.log('page2', window.location.href.split('/'))
            }
        }
        abcd()
    }, [currentPage])

    const handleChange = (e) => {
        setPainText(e.target.value.replace(/[&/\\#,@=!`|[\]`“()$~%.":*?<>{}]/g, ""))
    }

    const handleSearch = (value) => {
        if (value.trim()) {
            if (currentPage === "search") {
                //history.push(`./search?q=${value.trim()}`)
                window.location.replace(`${process.env.REACT_APP_CLIENT}/search?q=${value.trim()}`)
            } else {
                window.location.replace(`${process.env.REACT_APP_CLIENT}/search?q=${value.trim()}`)
            }
            //Un-focus field
            document.activeElement.blur();
        } else {
            setPainText("")
        }
    }


    return (
        <Input.Search allowClear onSearch={handleSearch} onChange={handleChange}
            size="large" style={{ width: '100%' }
            } value={painText}
            placeholder={translate('header_searchPlaceHorder', language)}
            enterButton={<SearchOutlined style={{ color: '#fff', fontSize: '20px' }} />}
        />
    )
}

export default Searcher;