import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Button, Row, Col, Typography, Dropdown, Radio, Divider, Badge } from "antd";
import { ShoppingCartOutlined, LogoutOutlined } from "@ant-design/icons";
import { BiChevronDown } from "react-icons/bi";

import { translate } from '../../i18n';
import { setLanguage } from '../../store/actions/langActions';
import { isAuth, logout } from '../../helpers/auth';
//import ModalCountry from '../Modal/ChooseCountry';
// Styles
import "./Header.scss";

const RightNavbar = () => {
    let history = useHistory();

    const radioStyle = {
        display: "block",
        height: "30px",
        lineHeight: "30px",
    };

    const { language } = useSelector((state) => state.lang);
    const customer = useSelector((state) => state.customer);
    const currentCountry = useSelector((state) => state.localCountry)
    //JSON.parse(localStorage.getItem('currentCountry'))
    const cart = useSelector((state) => state.cart);
    const dispatch = useDispatch();

    const chooseLanguageHandler = e => {
        dispatch(setLanguage(e.target.value));
    }

    const signout = () => {
        logout()
        dispatch({
            type: "LOGOUT",
            payload: null,
        });
        history.push('/login')
    }

    return (
        <Row gutter={[10, 0]} justify="end" align="middle" order={1}>
            <Col lg={5} md={5} >
                <Dropdown
                    arrow
                    trigger={["click"]}
                    overlay={
                        <div>
                            <Radio.Group value={language} onChange={chooseLanguageHandler}>
                                <Radio style={radioStyle} value={"fr"}>
                                    Français - FR
                                </Radio>
                                <Divider
                                    style={{ marginTop: "10px", marginBottom: "10px" }}
                                />
                                <Radio style={radioStyle} value={"en"}>
                                    English - EN
                                </Radio>
                                <Divider
                                    style={{ marginTop: "10px", marginBottom: "10px" }}
                                />
                                <div>
                                    <p style={{ fontSize: "15px", marginLeft: "2px" }}>
                                        <img
                                            src={currentCountry && currentCountry.image}
                                            alt={currentCountry && currentCountry['name_' + language]}
                                            style={{ width: "18px", height: "10px" }}
                                        />
                                        <span style={{ fontSize: "14px", marginLeft: "5px" }}>
                                            {translate('header_currentSite', language)} Danixt.{currentCountry && currentCountry.domain}
                                        </span>
                                    </p>
                                </div>
                                <Divider style={{ border: "none", margin: "10px 0px" }} />
                                {/* <ModalCountry typeDisplay="header" /> */}
                            </Radio.Group>
                        </div>
                    }
                    overlayStyle={{
                        background: "#fff",
                        padding: "14px",
                    }}
                    overlayClassName="language__dropdown"
                    placement="bottomLeft"
                >
                    <div className="menu__right-language-container d-flex flex-align-center cursor-pointer-set">
                        <div className="navbar__language-selector">
                            <img
                                src={currentCountry && currentCountry.image}
                                alt={currentCountry && currentCountry['name_' + language]}
                                style={{ width: "18px", height: "10px" }}
                            />
                            {' - ' + language.toUpperCase()}
                            <BiChevronDown
                                style={{
                                    marginLeft: "0px",
                                    fontSize: "20px",
                                    color: "#fff",
                                    marginBottom: "-7px",
                                }}
                            />
                        </div>
                    </div>
                </Dropdown>
            </Col>

            <Col xl={10} lg={10} sm={10} className="tablet">
                <Dropdown
                    arrow
                    trigger={["click"]}
                    overlay={
                        <>
                            <Row className="pl-4 pr-4 pt-2 pb-3">
                                <Col span={11} style={{ borderRight: '1px solid #eee' }}>
                                    <Typography.Title level={5}>
                                        {translate('header_listTitle1', language)}
                                    </Typography.Title>
                                    <Link className="header-link" to="/favoris">
                                        <div>{translate('header_listWishProducts', language)}</div>
                                    </Link>
                                    <Link className="header-link" to="/mescartes">
                                        <div>{translate('header_listGiftCard', language)}</div>
                                    </Link>
                                    <Link className="header-link" to="/commandes">
                                        <div>{translate('header_listOrders', language)}</div>
                                    </Link>
                                    <Link className="header-link" to="/retours">
                                        <div>{translate('Customer_returns', language)}</div>
                                    </Link>
                                </Col>
                                <Col span={13} className="pl-4">
                                    <Typography.Title level={5} >
                                        {translate('header_listTitle2', language)}
                                    </Typography.Title>
                                    {
                                        !isAuth() && (
                                            <>
                                                <Link className="header-link" to="/login">
                                                    <div>{translate('header_listStartSession', language)}</div>
                                                </Link>
                                                <Link className="header-link" to="/register">
                                                    <div>{translate('header_listCreateAccount', language)}</div>
                                                </Link>
                                            </>
                                        )
                                    }
                                    <Link className="header-link" to="/moncompte">
                                        <div>{translate('header_listAccount', language)}</div>
                                    </Link>
                                    <Link className="header-link" to="/monprofil">
                                        <div>{translate('Customer_account', language)}</div>
                                    </Link>
                                    {
                                        isAuth() && (
                                            <Button onClick={signout} type="primary" ghost className="mt-1 mb-0">
                                                <LogoutOutlined style={{ color: 'var(--main-yellow)' }} /> {translate('header_listEndSession', language)}
                                            </Button>
                                        )
                                    }
                                </Col>
                            </Row>
                        </>
                    }
                    overlayStyle={{
                        background: "#fff",
                    }}
                    overlayClassName="accounts__dropdown"
                    placement="bottomLeft"
                >
                    <div className="accounts-and-lists__container cursor-pointer-set">
                        <div className="tablet navbar__account-selector">
                            <Typography.Text className="navbar__top-text-small">
                                {translate('header_hello', language)}, {customer && customer ? customer.firstName : translate('header_sigin', language)}
                            </Typography.Text>
                            <Typography.Title
                                level={5}
                                className="navbar__bottom-text-big"
                            >
                                {translate('header_accountList', language)}
                                <BiChevronDown
                                    style={{
                                        fontSize: "20px",
                                        color: "#fff",
                                        marginBottom: "-7px",
                                    }}
                                />
                            </Typography.Title>
                        </div>
                    </div>
                </Dropdown>
            </Col>

            {/* <Col span={6} className="returns-and-orders__container phone">
                <div className="navbar__returns-and-orders cursor-pointer-set">
                    <Link to="/giftCard">
                        <Typography.Text className="navbar__top-text-small">
                            {translate('header_gifcardLabel', language)}
                        </Typography.Text>
                        <Typography.Title
                            level={5}
                            className="navbar__bottom-text-big"
                        >
                            {translate('header_gifcardBold', language)}
                        </Typography.Title>
                    </Link>
                </div> 
            </Col>*/}
            <Col md={4} className="cart-button__container pl-0">
                <Link to="/cart">
                    <div className="navbar__shopping-cart cursor-pointer-set ml-3">
                        <Badge count={cart.length} showZero={true}
                            style={{
                                backgroundColor: "#f08804",
                                borderRadius: "3px",
                                height: "16px",
                                lineHeight: "16px",
                                boxShadow: "unset",
                            }}
                        >
                            <ShoppingCartOutlined style={{ fontSize: "26px", color: "#FFF" }} />
                        </Badge>
                    </div>
                </Link>
            </Col>
        </Row>
    )
}

export default RightNavbar;